@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap);
.Square_square__wzqWM{
    height:80px;
    width:80px;
}

.Square_black__1FbYX{
    background-color:rgb(161, 121, 95);
}

.Square_white__1GU5n{
    background-color:tan
}

.Square_highlighted__2abbj{
    background-color:rgb(255 226 99 / 21%);
}
img{
    height:80px;
    width:80px;
}
.Chessboard_board__2DcmF{
    display:flex;
    flex-flow:column nowrap;
    justify-content:center;
    align-content:center;
    height:auto;
    box-shadow: 6px 3px 3px 2px #24242446;
    z-index:99;
}
.Chessboard_row__3JJ4N{
    height:auto;
    display:flex;
    flex-flow:row;
}

* {
  text-align: center;
  box-sizing: border-box;
}
.Start_background__m_3JN {
  height: 100%;
  position: absolute;
  z-index: 98;
  width: 100%;
}

.Start_window__tKl0H {
  height: auto;
  width: 600px;
  background-color: #d9d5d5;
  position: absolute;
  margin: auto;
  z-index: 99;
  display: flex;
  flex-flow: column;
  padding: 30px;
  border-radius: 15px;
  background-color: rgb(104, 92, 92);
  box-shadow: 1px 1px 11px 2px rgba(0, 0, 0, 0.205);
  color: rgb(240, 219, 205);
  font-size: 1.3rem;
  line-height: 2rem;
  text-shadow: 2px 2px 2px #000000ad;

}

.Start_title__2czod {
  font-size: 3rem;
}
.Start_input__3pQXF {
  background-color: rgb(176 154 135 / 58%);
  padding:5px;
  line-height: 1.4rem;
  border-radius: 5px;
  max-width: 100px;
  box-shadow: 1px 1px 0px 0px rgba(51, 51, 51, 0.336);
  border: none;
  font-size:1.2rem;
  color:rgb(237 237 237);
  text-shadow: 1px 1px 1px #0000008a;
  outline:none;
  margin:2px;
  border:1px solid rgba(58, 48, 48, 0);
}
.Start_input__3pQXF:focus{
    background-color: rgb(158 141 120);
    border: 1px solid rgb(58, 48, 48);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.Start_label__1KM3r {
  font-size: 1.3rem;
}
.Start_label__1KM3r span {
  font-size: 0.9rem;
}
.Start_button__3lwTa{
  margin:10px;
  font-size:1.1rem;
  padding:15px 35px;
  background-color:#312c28;
  color:white;
  border-radius:10px;
  box-shadow:1px -1px 1px 1px rgba(34, 30, 27, 0.13);
  border:none;
  outline:none
}
.Start_button__3lwTa:hover{
  background-color:rgb(243, 160, 92);
}
.Start_button__3lwTa:focus{
  background-color:rgb(243, 160, 92);
  border:1px solid rgb(71, 47, 14)
}
.Start_button__3lwTa:active{
  background-color:rgb(136, 91, 55);
}
.Loading_loading__3Dxnd{
    font-size:3rem;
    color:rgb(199, 192, 177);
}

.Timer_timer__dcrME{
    color:rgb(240, 219, 205);
    font-size:2.5rem;
    margin-bottom:30px;
    text-shadow:3px 3px 5px #3f3030
}   
.GameUI_window__30di8 {
  height: 500px;
  width: auto;
  background-color: rgb(104, 92, 92);
  border-radius: 0px 25px 25px 0px;
  box-shadow: 1px 0px 3px 3px rgba(0, 0, 0, 0.197);
  z-index: 98;
  padding: 25px;
}
.GameUI_form__3t-OZ {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  color: rgb(240, 219, 205);
}
.GameUI_input__Sph8v {
  background-color: rgb(176 154 135 / 58%);
  padding: 5px;
  line-height: 1.4rem;
  border-radius: 5px;
  max-width: 100px;
  box-shadow: 1px 1px 0px 0px rgba(51, 51, 51, 0.336);
  border: none;
  font-size: 1.2rem;
  color: rgb(237 237 237);
  text-shadow: 1px 1px 1px #0000008a;
  outline: none;
  margin: 2px;
  border: 1px solid rgba(58, 48, 48, 0);
}
.GameUI_input__Sph8v:focus {
  background-color: rgb(158 141 120);
  border: 1px solid rgb(58, 48, 48);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.GameUI_button__1VNMe {
  margin: 10px;
  font-size: 1.1rem;
  padding: 10px 15px;
  background-color: rgb(233, 140, 64);
  color: white;
  border-radius: 10px;
  box-shadow: 1px -1px 1px 1px rgba(34, 30, 27, 0.13);
  border: none;
  outline: none;
}
.GameUI_button__1VNMe:hover {
  background-color: rgb(243, 160, 92);
}
.GameUI_button__1VNMe:focus {
  background-color: rgb(243, 160, 92);
  border: 1px solid rgb(71, 47, 14);
}
.GameUI_button__1VNMe:active {
  background-color: rgb(136, 91, 55);
}
.GameUI_error__2RHjd {
  color: rgba(255, 138, 138, 0.849);
  margin: 10px;
}
.GameUI_tableHeader__sWfSK{
  display:flex;
  flex-flow: row nowrap;
  justify-content:space-between;
  margin-top:15px;
  background-color: rgb(63, 56, 56);
  color: rgb(237 237 237);
  border-radius:5px; 
  padding:5px;
}
.GameUI_tableHeader__sWfSK span{
  margin-right:15px;
}

.GameUI_tableRow__2xAZL{
  display:flex;
  flex-flow: row nowrap;
  justify-content:space-between;
  margin-top:10px;
  padding:5px;
  color: rgb(237 237 237);

}
.GameUI_tableRow__2xAZL span{
  margin-right:15px;
}
.Complete_background__28jl_ {
  background-color: rgba(122, 97, 97, 0.432);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Complete_window__UVJIq {
  width: 45%;
  padding: 50px;
  background-color: rgb(161, 177, 161);
  border-radius: 15px;
  box-shadow: 1px 1px 3px 3px rgba(94, 88, 88, 0.281);
}
.Complete_tableHeader__25cQi {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: 15px;
  background-color: rgb(63, 56, 56);
  color: rgb(237 237 237);
  border-radius: 5px;
  padding: 5px;
  font-size: 1.3rem;
}
.Complete_tableHeader__25cQi span {
  margin-right: 15px;
}

.Complete_tableRow__1YMfN {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: 10px;
  padding: 5px;
  color: rgb(53, 30, 9);
  font-size: 1.3rem;
}
.Complete_tableRow__1YMfN span {
  margin-right: 15px;
}
.Complete_results__2L5tj {
  margin-top: 25px;
  font-size: 1.7rem;
  line-height: 2.9rem;
}
.Complete_button__2ZQQf {
  margin: 10px;
  font-size: 1.1rem;
  padding: 15px 35px;
  background-color: rgb(46, 90, 44);
  color: white;
  border-radius: 10px;
  box-shadow: 1px -1px 1px 1px rgba(34, 30, 27, 0.13);
  border: none;
  outline: none;
}
.Complete_button__2ZQQf:hover {
    background-color: rgb(79, 151, 75);
}
.Complete_button__2ZQQf:focus {
  background-color: rgb(71, 131, 68);
  border: 1px solid rgb(71, 47, 14);
}
.Complete_button__2ZQQf:active {
  background-color: rgb(24, 46, 23);
}

.Countdown_countdown__GUbtZ{
    font-size:3rem;
    color:rgb(199, 192, 177);
}
*{
    font-family: 'Montserrat', sans-serif;
}
body{
    margin:0;
    box-sizing: border-box;
}

.App_app__31x5q{
    height:100vh;
    background-color:rgb(61 59 59);
    display:flex;
    justify-content:center;
    align-items:center;
}

